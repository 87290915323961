






import rService from '../services/RequestService/RequestServiceSetup';
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      info: null,
      timer: null,
    };
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    color() {
      return this.info && this.info.bypassEnabled ? 'green' : 'red';
    },
    text() {
      return this.info && this.info.bypassEnabled ? 'Working' : 'Waiting for Cloudflare Bypass';
    },
  },
  methods: {
    async getInfo() {
      this.info = await rService.get(`dehashed_bypass_status/${this.userInfo.platform}`, true);
      this.timer = setTimeout(() => {
        this.getInfo();
      }, 30000);
    },
  },
  mounted() {
    if (this.userInfo.unique_id) {
      this.getInfo();
    }
  },
};
