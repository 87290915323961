<template>
  <vs-popup :light="false" colorCard="navBarBlue" class="popupProfile" :title="address" :active.sync="popupActive">
    <v-card :loading="loading" class="profile-info pb-3 pt-3 px-3">
      <div v-if="addressData">
        <v-list dense color="transparent">
          <v-list-item v-for="(key, i) in objectKeys(addressData)" :key="i">
            <v-list-item-content v-if="key !== 'location'">
              <v-list-item-title>{{ capitalize(key) }}</v-list-item-title>
              <span @click="copy(addressData[key])">{{ addressData[key] }}</span>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="addressData && addressData.latitude">
            <v-list-item-content>
              <div style="height: 400px">
                <l-map :zoom="zoom" :center="center" style="height: 100%; width: 100%" ref="myMap">
                  <l-tile-layer :url="url"></l-tile-layer>
                  <l-circle-marker :lat-lng="center" :radius="19" color="red" />
                </l-map>
              </div>
            </v-list-item-content>
          </v-list-item>
          <v-list-item class="no_link" link target="_blank" :href="srcIpMaps(addressData)">
            <v-list-item-content>
              <v-list-item-title>Check in GoogleMaps</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
      <div v-else class="py-3">
        <p class="mb-3">Sorry, no data found</p>
        <v-btn color="blue darken-4" :href="googleLink()" target="_blank" link class="no_link">Try with google</v-btn>
      </div>
    </v-card>
  </vs-popup>
</template>

<script>
import axios from 'axios';
import { copyTextToClipboard } from '../../services/Copy';
import { capitalize } from 'lodash';
import L from 'leaflet';
import { LMap, LTileLayer, LCircleMarker } from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LCircleMarker,
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
  },
  data() {
    return {
      zoom: 70,
      center: [47.41322, -1.219482],
      addressData: [],
      popupActive: true,
      loading: true,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };
  },
  props: {
    address: {
      type: String,
      required: true,
    },
  },
  watch: {
    popupActive(newVal) {
      if (newVal == false) {
        this.$emit('close', true);
      }
    },
  },
  beforeMount() {
    this.getAddressData();
  },
  methods: {
    googleLink() {
      return 'https://maps.google.com/maps?q=' + encodeURIComponent(this.address)
    },
    srcIpMaps({ latitude, longitude }) {
      return `https://maps.google.com/maps?q=${latitude},${longitude}&hl=es&z=14&amp;output=embed`;
    },
    iframeMaps({ latitud, longitude }) {},
    getLink({ latitude, longitude }) {
      return `https://www.google.com.uy/maps/@${latitude},${longitude}`;
    },
    capitalize(item) {
      return capitalize(item.replace(/\_/g, ' '));
    },
    objectKeys(item) {
      return Object.keys(item);
    },
    copy(text) {
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    cF(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    fixData(data) {
      if(data.lon) data.longitude = data.lon;
      if(data.lat) data.latitude = data.lat;
      delete data.lon;
      delete data.lat;
      return data;
    },
    fixAddress(addr) {
      if(addr) {
        addr = addr.replace('bis', '');
      }
      return addr
    },
    async getAddressData() {
      const address = this.fixAddress(this.address);
      this.addressData = await axios.get('//nominatim.openstreetmap.org/search?format=json&q=' + encodeURIComponent(address)).then(res => res.data && res.data.length ? this.fixData(res.data[0]) : null);
      if (this.addressData && this.addressData.latitude) {
        this.center = [this.addressData.latitude, this.addressData.longitude];
      }
      this.loading = false;
    },
  },
};
</script>