






import { Link } from '@/services/constants';

export default {
  name: 'BreachChips',
  props: {
    sources: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      breach: undefined,
    };
  },
  methods: {
    openFullInfoPopup(change) {
      if (!isNaN(change)) {
        const item = this.sources[change];
        const current = this.$store.state.activeBreach;
        if (current !== item.Name) {
          this.$store.commit('breachPopup', item.Name);
        }
        this.breach = undefined;
      }
    },
    processColor(link: Link) {
      if (link.unknown) {
        return 'grey';
      }
      if (link.dehashed) {
        return '#2C6BD4';
      }
      const color = link.leakCheck && !link.hbwPwned ? '#5446F8' : link.hbwPwned && !link.leakCheck ? '#3a9ac4' : link.leakCheck && link.hbwPwned ? 'red lighten-1' : 'black';
      return color;
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
  },
};
