



































































































































































































































import alertS from '../../services/AlertService/AlertService';
export default {
  name: 'SingleLeak',
  data() {
    return {
      item: null,
      info: {
        line: '',
        sources: [],
      },
      items: [],
      dialog: false,
    };
  },
  methods: {
    getPassword(info) {
      if (info.password) {
        return info.password;
      }
      if (info.hashed_password) {
        return info.hashed_password;
      }
    },
    open(info) {
      this.dialog = true;
      this.info = JSON.parse(JSON.stringify(info));
    },
    title(item) {
      return `${item.Name}`;
    },
    openPopup(item) {
      this.$store.commit('breachPopup', item.Name);
    },
    text(item) {
      if (item && item.Sources) {
        const found = item.Sources;
        return `Breach found in ${found.join(', ')}`;
      }
      return '';
    },
    ToClipboard(text) {
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.position = 'fixed';

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }

      document.body.removeChild(textArea);
    },
  },
};
