<template>
  <vs-popup :light="false" colorCard="navBarBlue" class="popupProfile" :title="phone" :active.sync="popupActive">
    <PhonePopupContent :phone="phone" />
  </vs-popup>
</template>

<script>
import PhonePopupContent from './PhonePopupContent.vue';

export default {
  components: {
    PhonePopupContent,
    'vs-popup': () => import('@/components/Popup/VSPopup.vue'),
  },
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      popupActive: true,
    };
  },
  watch: {
    popupActive(newVal) {
      if (newVal == false) {
        this.$emit('close', true);
      }
    },
  },
};
</script>