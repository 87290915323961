<template>
  <v-card class="profile-info pb-3 pt-3 px-3">
    <div v-if="data">
      <v-list dense color="transparent">
        <v-list-item v-for="(key, i) in objectKeys(data)" :key="i">
          <v-list-item-content>
            <v-list-item-title>{{ capitalize(key) }}</v-list-item-title>
            <span @click="copy(data[key])">{{ data[key] }}</span>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-btn :disabled="!link" class="no_link mt-4" :href="link" color="primary" target="_blank" link>
            <v-icon class="mr-2">mdi-whatsapp</v-icon>

            <span>Whatsapp Checker</span>
          </v-btn>
        </v-list-item>
      </v-list>
    </div>
    <div v-else>No data available for this phone number</div>
  </v-card>
</template>

<script>
import parsePhoneNumber from 'libphonenumber-js';
import { copyTextToClipboard } from '../../services/Copy';
import { capitalize } from 'lodash';

export default {
  name: 'PhoneInfoContent',
  props: {
    phone: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      data: null,
    };
  },
  beforeMount() {
    this.getPhoneData();
  },
  methods: {
    capitalize(item) {
      return capitalize(item.replace(/\_/g, ' '));
    },
    objectKeys(item) {
      const exclude = ['getMetadata', '__countryCallingCodeSource'];
      return Object.keys(item).filter((el) => !exclude.includes(el));
    },
    copy(text) {
      const res = copyTextToClipboard(text);
      try {
        const successful = document.execCommand('copy');
        const msg = successful ? 'successful' : 'unsuccessful';
        this.$copied(`<p>Copied to clipboard:<br>${text}</p>`);
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err, text);
      }
    },
    getRealPhone() {
      let realPhone = this.phone.replace(/[^0-9]/g, '').trim();
      if (!realPhone.startsWith('+')) {
        realPhone = '+' + realPhone;
      }
      return realPhone;
    },
    getPhoneData() {
      const realPhone = this.getRealPhone();
      this.data = parsePhoneNumber(realPhone);
      //this.data.metadata = this.data.get();
    },
  },
  computed: {
    link() {
      if (!this.data.countryCallingCode) return '';
      if (!this.data.nationalNumber) return '';
      return `https://whatsapp.checkleaked.cc/${this.data.countryCallingCode}${this.data.nationalNumber}`;
    },
  },
};
</script>

