


























































































































































































































/*

*/
import BreachChips from '@/components/BreachComponents/Chip.vue';
import DialogBreach from '@/components/BreachComponents/DialogBreach.vue';
import DialogAlert from '@/components/CheckAccount/Alert.vue';
import DehashedDialog from '@/components/CheckAccount/Dehashed.vue';
import AlertPlans from '@/components/CheckAccount/PlansAlerts.vue';
import SingleLeak from '@/components/CheckAccount/SingleLeakDehashed.vue';
import UsesLeft from '@/components/CheckAccount/UsesLeft.vue';
import DehashedStatus from '@/components/DehashedStatus.vue';
import IPInfo from '@/components/IPPopup/IPInfo.vue';
import PhoneInfo from '@/components/PhonePopup/PhoneInfo.vue';
import MapDialog from '@/components/MapDialog/MapDialog.vue';
import { getReferences, getRulesDehashed, getTypesDehashed, Link } from '@/services/constants';
import hashing from '@/services/Hashing';
import { mapActions, mapGetters } from 'vuex';
import XLSX from 'xlsx';
import alertS from '../services/AlertService/AlertService';
import rService from '../services/RequestService/RequestServiceSetup';

export default {
  name: 'Home',
  components: {
    BreachChips,
    DehashedStatus,
    DehashedDialog,
    UsesLeft,
    DialogAlert,
    SingleLeak,
    AlertPlans,
    IPInfo,
    PhoneInfo,
    DialogBreach,
    MapDialog,
    PlanData: () => import('@/components/PlanData.vue'),
  },
  data() {
    return {
      disable_settings: true,
      sendDiscord: false,
      sendTelegram: false,
      sendEmail: false,
      saveEntry: true,
      snusBase: false,
      ipSel: '',
      phoneSel: '',
      addrSel: '',
      itemsPerPage: 20,
      entries: 0,
      isDehashedSearch: false,
      currentPage: 1,
      totalPages: 1,
      saved: {},
      alertPlans: true,
      enable: false,
      colorCard: 'primary',
      instantFilter: true,
      propsSearch: '1',
      absolute: true,
      lastSearch: '',
      searchItem: '',
      previousPage: 1,
      loadingBreaches: false,
      breachesFound: [],
      breaches: [],
      filtered: [],
      pagination: {
        page: 1,
      },
      errorDisplay: true,
      sourceTypes: [],
      allItems: [],
      references: this.getReferences(),
      findLine: null,
      safe: null,
      typeSelected: {
        name: String,
        value: String,
      },
      validFormSearch: false,
      disableSearchBtn: true,
      search: '',
      toSearch: '',
      toSearchSel: '',
      searchDisabled: true,
      searchHolder: this.$vuetify.lang.t('$vuetify.Home.searchHolder'),
      loading: false,
      lastType: '',
      toSearchVal: '',
      allValues: [],
      values: [],
      searchInputValue: '',
      typeSelP: '',
      entrySearch: '',
      hash_types: JSON.parse(JSON.stringify(hashing.hash_types)),
      hash_type: '',
      hash_applied: false,
      backup_search: '',
      f: false,
    };
  },
  methods: {
    async use_my_ip() {
      const endPoint = 'my_ip';
      const res = await rService.get(endPoint);
      if (res.ip) {
        this.toSearch = res.ip;
      }
    },
    scrollTopTable() {
      if (this.f) {
        this.f = false;
        return;
      }
      const tTable = this.$refs.top_table;
      if (tTable)
        this.$vuetify.goTo(tTable, {
          duration: 300,
          offset: 0,
          easing: 'easeInOutCubic',
        });
    },
    editType(type) {
      if (type === 'hashed_password') {
        type = 'hash';
      }
      return type;
    },
    getLink(name: string) {
      return {
        name: name,
        params: {
          id: 0,
          entry: this.toSearch,
          type: this.editType(this.typeSelected),
        },
      };
    },
    applyHash() {
      hashing.applyHash(this);
    },
    async sendUpdates() {
      if (this.userInfo.unique_id) {
        await this.sendTelegramUpdate();
        await this.sendDiscordUpdate();
      }
    },
    async checkPlatform(platform: string, prop: string, alert = false) {
      const checkApi = await rService.get(`check_bot_link/${platform}`);
      const userId = checkApi.user_id;
      if (!userId && this[prop]) {
        const route = this.$router.resolve({ name: 'botLinks' });
        if (alert) {
          const text = this.translate('linkRequest');
          const str = text.replace('<platform>', platform);
          alertS.error(`${str}<a target="_blank" href="${route.href}">${route.href}</a>`);
        }
        this[prop] = false;
      } else if (alert) {
        this.saveSettings();
      }
    },
    async sendDiscordUpdate(alert = false) {
      await this.checkPlatform('discord', 'sendDiscord', alert);
    },
    async sendTelegramUpdate(alert = false) {
      await this.checkPlatform('telegram', 'sendTelegram', alert);
    },
    paramsUpdate(dehashdSearch = false) {
      if (dehashdSearch || !this.isEntrySearch()) {
        const route = this.$route;
        if (route.params) {
          const params = route.params;
          if (params.type && params.entry) {
            this.toSearch = params.entry;
            this.typeSelected = params.type;
          } else if (params.id) {
            this.entrySearch = params.entrySearch;
            this.loadFromId(params.id, route.name);
          } else {
            this.typeSelected = params.typeSelected;
            this.toSearch = params.toSearch;
          }
        } else {
          this.typeSelected = '';
          this.toSearch = '';
        }
        this.isDehashedSearch = false;
        this.saved = {};
        this.values = [];
        this.breaches = [];
      } else {
        this.isDehashedSearch = true;
        this.typeSelected = 'free';
        this.toSearch = this.$route.params.query;
        this.pagination.pages = parseInt(this.$route.params.pages.split(',')[0]);
        this.typeSelP = `${this.typeSelected}`;
        this.lastSearch = `${this.toSearch}`;
        this.checkBreachesPagination(1);
      }
    },
    exportExcel: function () {
      const dataToExport = [];
      Object.values(this.saved).forEach((elSingle: any) => {
        elSingle.entries.forEach((element) => {
          const { address, email, hashed_password, ip_address, obtained_from, password, phone, vin, username, name } = element.entry;
          dataToExport.push({
            Email: email,
            Username: username,
            Name: name,
            Password: password,
            'Hashed Password': hashed_password,
            Address: address,
            Phone: phone,
            VIN: vin,
            IP: ip_address,
            Source: obtained_from,
          });
        });
      });
      const data = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      let filename = `${this.typeSelP}_${this.lastSearch}`;
      if (filename.length > 30 || filename.includes('*')) {
        filename = 'downloadDehashed';
      }
      try {
        XLSX.utils.book_append_sheet(workbook, data, filename);
      } catch (e) {
        XLSX.utils.book_append_sheet(workbook, data, 'downloadDehashed');
      }
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    getTotalItems() {
      let total = 0;
      Object.values(this.saved).forEach((element: any) => {
        if (element.entries) total += element.entries.length;
      });
      return total;
    },
    showTracking() {
      const isTracking = this.$router.currentRoute.name == 'trackingData';
      if (isTracking) return ' - Tracking';
      else return '';
    },
    row_classes(item) {
      if (item.password && item.hashed_password) {
        return 'blue darken-4';
      }
      return;
    },
    rowClick(value) {
      this.$refs.singleLeak.open(value);
    },
    ...mapActions(['setupTimer', 'destroyUserInfo', 'openSnack', 'updateUserInfo', 'setCooldown', 'openLogin']),
    loadBreaches() {
      this.loadingBreaches = true;
      this.breaches = [];
      this.values.forEach((el) => {
        el.sources.forEach((element) => {
          delete element.unknown;
          if (element.Name != 'Unknown') {
            if (this._.find(this.breaches, element) == undefined) this.breaches.push(element);
          }
        });
      });
      this.loadingBreaches = false;
    },
    checkDehashedG() {
      this.typeSelP = this.typeSelected;
      this.checkDehashed({
        email: this.toSearch,
      });
    },
    formatDate(date) {
      return this.$moment(date).format('YYYY-MM-DD');
    },
    timeAgo(date) {
      return this.$moment(date).fromNow();
    },
    checkDehashed(item) {
      const currentType = this.typeSelP;
      this.$refs.dehashedDialog.open(item, currentType);
    },
    changedSource() {
      const values = this.sourceTypes;
    },
    processColor(link: Link) {
      if (link.unknown) {
        return 'grey';
      }
      const color = link.leakCheck && !link.hbwPwned ? '#5446F8' : link.hbwPwned && !link.leakCheck ? '#3a9ac4' : link.leakCheck && link.hbwPwned ? 'red lighten-1' : 'black';
      return color;
    },
    processDisabled(link: Link) {
      return link.unknown || (link.leakCheck && !link.hbwPwned);
    },
    processDisabledRef(reference) {
      const val = reference.value;
      const selected = this.typeSelected;
      if (val == 'hibp') {
        const allowed = ['email', 'login'];
        return !allowed.includes(selected);
      }
    },
    getTypes() {
      return getTypesDehashed(this.translate);
    },
    getReferences() {
      return getReferences(this.translate);
    },
    customFilter(value, stringSearch, item) {
      /* Search parameters */
      const { findLine, sourceTypes, breachesFound, searchItem } = {
        findLine: this.findLine,
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };

      if (breachesFound && breachesFound.length) {
        const oneBrachF = breachesFound.find((el) => {
          return (
            item.sources.find((elS) => {
              return elS.Name == el;
            }) != undefined
          );
        });
        if (oneBrachF == undefined) return false;
      }
      if (sourceTypes && sourceTypes.length) {
        const itemSources = item.sources.find((el) => {
          const elLinked = el.Linked && el.Linked.unknown ? { unknown: true } : { ...el.Linked };
          return this._.find(sourceTypes, { Linked: elLinked });
        });
        if (itemSources == undefined) {
          return false;
        }
      }

      // if (findLine) return item.line == findLine;
      if (findLine && item.combo) {
        if (typeof findLine === 'string') return item.combo.indexOf(findLine) !== -1;
        else return item.combo == findLine.combo;
      }

      // return item.combo != null && searchItem != null && item.combo.indexOf(findLine) !== -1;
      return true;
    },
    resetFields(ev) {
      this.values = [];
      this.allValues = [];
      this.breaches = [];
      this.typeSelected = '';
      this.breachesFound = [];
      this.searchInputValue = '';
      this.safe = null;
      this.colorCard = 'primary';
    },
    updateFiltered(items) {
      this.filtered = [];
      if (this.$refs.tableLeaks) this.filtered = this.$refs.tableLeaks.$children[0].filteredItems;
      else this.filtered = items;
    },
    goPricing() {
      const access_token = this.$store.state.userInfo.token;
      if (!access_token) {
        this.openLogin();

        // alertS.error('You have to login first');
      } else {
        this.$router.push({ name: 'pricing' }).catch();
      }
    },
    cleanLastResult() {
      this.f = true;
      this.entries = 0;
      this.pagination.page = 1;
      this.currentPage = 1;
      this.totalPages = 1;
      this.findLine = null;
      this.sourceTypes = [];
      this.breachesFound = [];
      this.searchItem = '';
      this.f = true;
    },
    async areYourSure() {
      this.$refs.dialogAlert.open();
    },
    fullPotentialDSearch() {
      window.open('https://www.dehashed.com/search-guide');
    },
    async unlockPasswords() {
      const query = this.getQuerySearch();
      const page = this.pagination.page;
      let hashedCount = 0;
      this.values.forEach((element) => {
        if (element.hashed_password && !element.password) {
          hashedCount++;
        }
      });
      if (hashedCount == 0) {
        return alertS.error(`No passwords to dehash in this page<br><strong>Search:</strong> ${query}<br><strong>Page</strong>: ${page}`);
      }
      this.$swal
        .fire({
          title: 'Thanks to hashes.org for this service',
          html: `You have ${hashedCount} hashed password<br><strong>Search:</strong> ${query}<br><strong>Page:</strong> ${page}`,
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: 'Look for them',
        })
        .then(async (result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.$swal.showLoading();
            const endPoint = 'search/dehashed/unlock_hashes';
            const res = await rService.post(endPoint, { query, page });
            if (res && res.result) {
              this.loadValuesPagination(page, res.result);
              this.$swal.fire('Page Updated!', '', 'success');
            }
          }
        });
    },
    async unlockAllResults() {
      this.loading = true;
      const pages = this.$route.params.pages.split(',');
      for (let index = 0; index < pages.length; index++) {
        const page = pages[index];
        if (!this.saved[page]) {
          const leaks = await this.searchReq(false, page);
          this.saved[page] = leaks;
          this.loadPaginationClass();
        }
      }
      this.loading = false;
    },
    async loadFromId(id, routeName) {
      try {
        if (routeName == 'trackingData') {
          this.loading = true;
          const entryObj = await rService.get(`tracking/history/${id}`);
          if (entryObj) {
            const { entry, type, result, creation_date, changes } = entryObj;
            this.typeSelected = type;
            this.toSearch = entry;
            this.typeSelP = `${this.typeSelected}`;
            this.lastSearch = `${this.toSearch}`;
            this.loadValues(result, true);
          }
          this.loading = false;
        } else if (this.userInfo && this.userInfo.platform) {
          this.loading = true;
          const entries = await rService.get(`my_entries/${this.userInfo.platform}?id=${id}`);
          if (entries.length) {
            const { entry, data, type } = entries[0];
            this.typeSelected = type;
            this.toSearch = entry;
            this.typeSelP = `${this.typeSelected}`;
            this.lastSearch = `${this.toSearch}`;

            this.loadValues(JSON.parse(data), true);
          }
          this.loading = false;
        }
      } catch (e) {
        this.loading = false;
        alertS.error('Error loading search');
      }
    },
    loadPaginationClass() {
      const items = document.querySelectorAll('.v-pagination__item');
      items.forEach((el) => {
        const text = parseInt(el.textContent);
        if (this.saved.hasOwnProperty(text)) {
          if (!el.classList.contains('scrapped')) el.classList.add('scrapped');
        }
      });
    },
    loadValues(leaks, noAlert) {
      const { found, foundWithPass, results } = leaks;
      if (!noAlert) {
        this.$swal.close();
        // alertS.error(`Se han encontrado ${found} brechas<br>${foundWithPass} de ellas con contraseñas expuestas`);
      }
      this.values = results.map((el) => {
        /** Setup Last Breach Date */
        if (el && el.lastBreach && el.lastBreach.BreachDate) el.lastBreachD = el.lastBreach.BreachDate;
        else el.lastBreachD = new Date('10-10-1970');

        const arrLine = el.line.split(':');
        el.email = arrLine[0].toLowerCase();
        el.password = arrLine[1];
        el.combo = `${arrLine[0]}:${arrLine[1]}`;
        return el;
      });
      this.allValues = Object.assign(this.values);
      this.loadBreaches();
    },
    async getSettings() {
      if (!this.userInfo.unique_id) return;

      const isPremium = this.isPremiumUser;
      if (!isPremium) {
        this.disable_settings = true;
        this.sendDiscord = false;
        this.sendTelegram = false;
        this.sendEmail = false;
        this.saveEntry = false;
        return;
      }

      this.disable_settings = true;
      const settings = await rService.post(`get_settings`, { table: 'settings_1' }, true, false);
      if (settings) {
        this.sendDiscord = settings.sendDiscord;
        this.sendTelegram = settings.sendTelegram;
        this.sendEmail = settings.sendEmail;
        this.saveEntry = settings.saveEntry;

        if (this.sendDiscord) {
          this.sendDiscordUpdate();
        }
        if (this.sendTelegram) {
          this.sendTelegramUpdate();
        }
      }
      this.disable_settings = false;
    },
    async saveSettings() {
      if (!this.userInfo.unique_id) return;
      this.disable_settings = true;
      await rService.post(
        `save_settings`,
        {
          table: 'settings_1',
          sendDiscord: this.sendDiscord,
          sendTelegram: this.sendTelegram,
          sendEmail: this.sendEmail,
          saveEntry: this.saveEntry,
        },
        true,
        false
      );
      this.disable_settings = false;
    },
    loadValuesPagination(pageNumber, leaks) {
      /** Leaks is result with entry,result,page,pages */
      const { entries, results, pages, page } = leaks;
      this.pagination.page = page;
      this.totalPages = pages;
      if (entries) this.entries = results;
      if (entries && entries.length) {
        this.values = entries.map(function (el) {
          return el.entry;
        });
        this.saved[pageNumber] = leaks;
        // await this.db.save({ id: pageNumber, value: leaks });
      }

      this.$nextTick(() => {
        this.loadPaginationClass();
        // Code that will run only after the
        // entire view has been rendered
      });
    },
    async checkBreachesPagination(pageNumber: number) {
      /* const res = await this.db.getItem(pageNumber).catch(e => {
        return null;
      }); */
      const res = { value: this.saved[pageNumber] };
      let leaks: any;
      if (res && res.value) {
        leaks = res.value;
      } else {
        leaks = await this.searchReq(false, this.pagination.page);
        if (!leaks || leaks.error) {
          // Go to page one
          this.pagination.page = this.previousPage;
          return;
        }
      }
      return this.loadValuesPagination(pageNumber, leaks);
    },

    async tryAgain(query, page, loading, errorMessage: string) {
      return new Promise((resolve) => {
        let timerInterval;
        this.$swal
          .fire({
            icon: 'error',
            title: 'Retrying in 5 seconds!',
            html: errorMessage + '<br>I will retry in <b></b> milliseconds.',
            timer: 5000,
            timerProgressBar: true,
            confirmButtonText: 'Cancel',
            willOpen: () => {
              this.$swal.showLoading();
              timerInterval = setInterval(() => {
                const content = this.$swal.getContent();
                if (content) {
                  const b = content.querySelector('b');
                  if (b) {
                    b.textContent = this.$swal.getTimerLeft();
                  }
                }
              }, 100);
            },
            onClose: () => {
              this.loading = false;
              clearInterval(timerInterval);
              // return resolve({ error: 'Operation Cancelled' });
            },
          })
          .then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === 'timer') {
              return resolve(this.searchInstance(query, page, loading));
            } else {
              this.pagination.page = this.previousPage;
              return resolve({ error: 'Operation Cancelled' });
            }
          });
      });
    },

    setPaginationSize(query) {
      if (query.includes('size=')) {
        const itemsPerPage = parseInt(query.split('size=')[1].split('&')[0]);
        this.itemsPerPage = itemsPerPage;
        this.pagination.itemsPerPage = itemsPerPage;
      } else {
        this.itemsPerPage = 20;
        this.pagination.itemsPerPage = 20;
      }
    },

    async searchInstance(query, page, loading) {
      const postBody = {
        query,
        page,
        sendDiscord: this.sendDiscord,
        sendTelegram: this.sendTelegram,
        sendEmail: this.sendEmail,
        hideEntry: !this.saveEntry,
      };
      this.setPaginationSize(query);
      this.loading = true;
      if (loading) alertS.loading(this.translate('checkingForLeaks'));
      const leaks = await rService.post(`search/dehashed/${this.userInfo.platform}`, postBody, true);
      if (leaks.error) {
        /** Try Again One More Time */
        this.errorDisplay = false;
        if (leaks.error && (leaks.error.includes('You need') || leaks.error.includes('Max number of checks'))) {
          this.loading = false;
          return { error: leaks.error };
        }
        return this.tryAgain(query, page, loading, leaks.error);
      } else {
        this.setupTimer();
      }
      this.loading = false;
      return leaks;
    },

    async searchDBInstance(query, page, loading) {
      const postBody = {
        query,
        page,
      };
      this.loading = true;
      let leaks = await rService.post('user_searches/ds/get_entry', postBody, true);
      if (leaks.error) {
        /** Try Again One More Time */
        this.pagination.page = this.previousPage;
        if (leaks.error == 'No search done yet for this entry') {
          /** Search in Browser */
          leaks = await this.searchInstance(query, page, loading);
          return leaks;
        } else {
          alertS.error(leaks.error);
        }
      } else {
        leaks = leaks.result;
      }
      this.loading = false;
      return leaks;
    },

    getQuerySearch() {
      let toSearch = this.lastSearch;
      let typeSelected = this.typeSelP;
      if (typeSelected == 'email') {
        //const [email, domain] = toSearch.split('@');
        toSearch = toSearch.trim();
      }
      if (typeSelected == 'name' || typeSelected == 'free' || typeSelected === 'username' || typeSelected === 'address') {
        toSearch = toSearch.replace(/\s/g, '+');
      }
      if (typeSelected == 'free') {
        typeSelected = '';
      }
      let query = toSearch;
      if (typeSelected) {
        query = `${typeSelected}:${toSearch}`;
      }
      return query;
    },

    async searchReq(loading = true, page = 1) {
      const query = this.getQuerySearch();
      let leaks: any;
      this.setPaginationSize(query);
      if (this.isDehashedSearch) {
        leaks = await this.searchDBInstance(query, page, loading);
      } else {
        leaks = await this.searchInstance(query, page, loading);
      }
      return leaks;
    },
    async checkBreaches() {
      this.cleanLastResult();
      this.saved = {};
      this.values = [];
      this.breaches = [];

      this.setupTimer();
      this.updateUserInfo();

      this.typeSelP = `${this.typeSelected}`;
      this.lastSearch = `${this.toSearch}`;

      const leaks = await this.searchReq(true, this.pagination.page);
      if (leaks.error) {
        const error = leaks.error;
        if (error == 'No user') {
          leaks.error = 'User session expired..';
          this.destroyUserInfo();
        }
        return alertS.error(leaks.error);
      }
      const { entries, results, pages, page } = leaks;
      this.pagination.page = page;
      this.totalPages = pages;
      this.entries = results;

      if (entries && !entries.length) {
        alertS.success(this.translate('success'));
        this.values = [];
        this.allValues = [];
        this.safe = true;
        this.colorCard = 'safe';
      } else if (entries && entries.length) {
        // await this.db.deleteAll();
        this.safe = false;
        this.colorCard = 'unsafe';
        this.values = entries.map(function (el) {
          return el.entry;
        });
        this.saved[page] = leaks;
        this.loadPaginationClass();
      }
      this.$swal.close();
      this.loading = false;
      this.setCooldown({ status: true, resetTimeLeft: true });
    },
    cleanSearch() {
      this.hash_applied = false;
      this.backup_search = '';
      // this.toSearch = '';
      const searchField: any = document.querySelector('.searchFieldCol .mdi-close');
      if (searchField) {
        try {
          searchField.click();
        } catch (e) {
          console.error(e);
        }
      }
    },
    searchNow() {
      this.values = this.allValues.filter((el) => {
        return this.customFilter(null, null, el);
      });
    },
    checkType(type) {
      if (type != this.lastType) {
        this.cleanSearch();
        const placeHolderType = this.placeHolderLink[type];
        if (!placeHolderType) {
          this.searchHolder = this.$vuetify.lang.t('$vuetify.Home.searchHolder');
          this.searchDisabled = true;
          this.disableSearchBtn = true;
        } else {
          this.searchHolder = `${this.$vuetify.lang.t('$vuetify.Home.enter')} ${placeHolderType}`;
          this.searchDisabled = false;
          this.disableSearchBtn = false;
        }
        this.lastType = type;
      }
    },
    translate(item) {
      return this.$vuetify.lang.t('$vuetify.Home.' + item);
    },
    getRules() {
      return getRulesDehashed(this.translate);
    },
    isEntrySearch() {
      return this.$route.name == 'dehashedSearchEntry';
    },
    download(sources) {
      let text = '';
      let title = '';
      if (sources) {
        title = `${this.lastSearch} with sources ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return `${el.combo} [${el.sources
              .map((source) => {
                return source.Name;
              })
              .join(',')}]`;
          })
          .join('\n');
      } else {
        title = `${this.lastSearch} ${this.$moment().format('YYYY-MM-DD')}`;
        text = this.filtered
          .map((el) => {
            return el.combo;
          })
          .join('\n');
      }
      const FileSaver = require('file-saver');
      const blob = new Blob([text], { type: 'text/plain;charset=utf-8' });
      FileSaver.saveAs(blob, `${title}.txt`);
    },
  },
  computed: {
    isPremiumUser() {
      return this.userInfo && ['pro', 'plus'].includes(this.userInfo.plan);
    },
    dehashedDisabled() {
      return !this.validFormSearch || this.disableSearchBtn || this.limitReached || this.activeCooldown;
    },
    isUserResult() {
      const userKeys = ['login', 'pass_login'];
      return userKeys.includes(this.typeSelP);
    },
    maxHeightSourcesMenu() {
      if (this.windowWidth < 768) return 150;
      return 304;
    },
    types() {
      try {
        const allowedTypes = this.timerInfo.allowedTypes;
        return this.getTypes();
      } catch (e) {
        return this.getTypes().map(function (el) {
          el.disabled = true;
          return el;
        });
      }
    },
    headers() {
      const toReturn = [
        { text: 'Email', value: 'email', width: 'auto' },
        { text: 'Username', value: 'username', width: 'auto' },
        { text: 'Password', value: 'password', width: 'auto' },
        { text: 'Hashed Password', value: 'hashed_password', width: 'auto' },
        { text: 'Name', value: 'name', width: 'auto' },
        { text: 'VIN', value: 'vin', width: 'auto' },
        { text: 'Address', value: 'address', width: 'auto' },
        { text: 'IP Address', value: 'ip_address', width: 'auto' },
        { text: 'Phone', value: 'phone', width: 'auto' },
        { text: 'Source', value: 'obtained_from', width: 'auto' },
      ];
      return toReturn;
    },
    propsSearchItem() {
      return {
        findLine: this.findLine,
        sourceTypes: this.sourceTypes,
        breachesFound: this.breachesFound,
        searchItem: this.searchItem,
      };
    },
    rules: function () {
      const { generalRules, ruleTypes } = this.getRules();
      const extra = ruleTypes[this.typeSelected];
      if (extra) {
        return [...generalRules, ...extra];
      } else {
        return [];
      }
    },
    placeHolderLink: function () {
      const t = this.translate;
      return {
        free: 'Free Lookup',
        email: 'Email',
        username: 'Username',
        name: 'Name',
        ip_address: 'IP',
        address: 'Address',
        phone: 'Phone',
        vin: 'VIN',
        domain: 'Domain',
        password: 'Password',
        hashed_password: 'Hashed Password',
      };
    },
    ...mapGetters([
      'activeCooldown',
      'userInfo',
      'loggedIn',
      'timerInfo',
      'limitReached',
      'limitReachedAbs',

      // ...
    ]),
    ...mapGetters({
      connected: 'socket/connected',
    }),
    overlay() {
      return !(this.userInfo.plan && this.userInfo.plan != 'unverified');
    },
  },
  mounted() {
    this.getSettings();
    this.sendUpdates();
    this.paramsUpdate();
  },
  beforeMount() {
    this.enable = true;
  },
  beforeDestroy() {
    this.$store.commit('updateSafe', null);
  },
  created() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type == 'updateCheckInput') {
        this.typeSelected = state.toCheck.type;
        this.toSearch = state.toCheck.info;
      }
    });
  },
  watch: {
    'pagination.page'(newValue, oldValue) {
      if (this.values.length > 0 && oldValue !== newValue) {
        this.checkBreachesPagination(newValue);
      }
    },
    safe() {
      this.$store.commit('updateSafe', this.safe);
    },
    '$route.params.query'(newVal) {
      if (!newVal) {
        this.paramsUpdate(true);
      }
    },
    'userInfo.unique_id'(newVal) {
      const router = this.$router.currentRoute;
      if (router.params.id) {
        this.entrySearch = router.params.entrySearch;
        this.loadFromId(router.params.id);
      }
      this.getSettings();
      if (newVal) this.sendUpdates();
    },
    propsSearchItem: {
      handler(val) {
        if (this.instantFilter) this.searchNow();
      },
      deep: true,
    },
  },
};
