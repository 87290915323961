





































import { mapGetters } from 'vuex';
export default {
  name: 'Plans Alert',
  components: {
    'vs-popup': () => import('@/components/Popup/VSPopup.vue')
  },
  data() {
    return {
      popupActive: true
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  methods: {
    getPlan() {
      this.$router.push({ name: 'pricing' }).catch();
    },
    translate(text) {
      return this.$vuetify.lang.t('$vuetify.AlertDonation.' + text);
    }
  }
};
