









import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'TimeLeft',
  props: {
    colorCard: String
  },
  data() {
    return {};
  },
  methods: {
    translate(text: string) {
      return this.$vuetify.lang.t(`$vuetify.usesLeft.${text}`);
    },
    ...mapActions(['setupTimer']),
    fTime(number) {
      return ('0' + number).slice(-2);
    },
    displayTime(props) {
      return `${this.fTime(props.hours)}:${this.fTime(props.minutes)}:${this.fTime(props.seconds)}`;
    }
  },
  computed: {
    calcLeft() {
      if(this.timerInfo.max) {
        return parseInt(this.timerInfo.max) - parseInt(this.timerInfo.current);
      }
      return 0;
    },
    ...mapGetters(['timerInfo', 'userInfo'])
  },
  watch: {
    userInfo: {
      handler(val) {
        this.setupTimer();
      },
      deep: true
    }
  },
  mounted() {
    if (this.userInfo.token) this.setupTimer();
  }
};
