




































































import rService from '@/services/RequestService/RequestServiceSetup';
import alertS from '@/services/AlertService/AlertService';
import { mapGetters } from 'vuex';

export default {
  name: 'DehashedDialog',
  components: {
    BreachChips: () => import('@/components/BreachComponents/Chip.vue'),
  },
  data() {
    return {
      loading: false,
      dialog: false,
      breaches: [],
      entry: '',
      params: {
        entry: '',
        type: '',
      },
      info: {
        results: 0,
        total: 0,
      },
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    formatNumber(x: number) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    translate(item) {},
    async loadInfo(itemInfo, typeInfo) {
      try {
        const type = typeInfo;
        const entry = itemInfo.email;
        this.entry = entry;
        const postBody = {
          entry,
          type,
        };
        this.params = postBody;
        const res = await rService.post(`dehashed/${this.userInfo.platform}`, postBody, false, true);
        if (res.error) {
          this.dialog = false;
          return res;
        }
        const { breaches, info } = res;
        const { results, total } = info;
        this.info = info;
        this.breaches = info;
        this.breaches = breaches.map(({ source, entry_id, removal_link }) => {
          return {
            Name: source,
            Linked: {
              hbwPwned: false,
              leakCheck: false,
              dehashed: true,
            },
          };
        });
        this.loading = false;
      } catch (e) {
        return alertS.error(e.message);
      }
    },
    open(itemInfo, typeInfo) {
      this.loading = true;
      this.dialog = true;
      this.loadInfo(itemInfo, typeInfo);
    },
  },
};
