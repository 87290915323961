
































import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DialogAlert',
  props: {},
  data() {
    return {
      showAlert: false,
      dialog: false
    };
  },
  methods: {
    translate(text) {
      return this.$vuetify.lang.t(`$vuetify.Alert.${text}`);
    },
    close() {
      this.dialog = false;
    },
    open() {
      if (localStorage.noAlert == undefined || localStorage.noAlert == 'true') {
        this.dialog = true;
      } else {
        this.$emit('checkBreach', true);
        this.dialog = false;
      }
    },
    dontShowAgain(option: boolean) {
      this.showAlert = option;
      localStorage.noAlert = !option;
    },
    checkBreach() {
      this.$emit('checkBreach', true);
      this.dialog = false;
    }
  }
};
